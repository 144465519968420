<template>
    <b-card :title="$t('Add Municipality User')" v-show="dbIller.length>0">
        <validation-observer ref="simpleRules">
            <b-row class="match-height">
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('First Name')"
                        label-for="mc-first-name"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('First Name')"
                        >
                            <b-form-input
                                id="mc-first-name"
                                :placeholder="$t('First Name')"
                                v-model="first_name"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Last Name')"
                        label-for="mc-last-name"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Last Name')"
                        >
                            <b-form-input
                                id="mc-last-name"
                                :placeholder="$t('Last Name')"
                                v-model="last_name"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Email')"
                        label-for="mc-email"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|email"
                            :name="$t('Email')"
                        >
                            <b-form-input
                                id="mc-email"
                                :placeholder="$t('Email')"
                                v-model="email"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Phone')"
                        label-for="mc-phone"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|digits:11"
                            :name="$t('Phone')"
                        >
                            <b-form-input
                                id="mc-phone"
                                :placeholder="$t('Phone')"
                                v-model="phone"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Password')"
                        label-for="mc-password"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Password')"
                        >
                            <b-form-input
                                id="mc-password"
                                :placeholder="$t('Password')"
                                v-model="password"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Province')"
                        label-for="mc-il"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Province')"
                        >
                            <v-select
                                id="mc-il"
                                v-model="il"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :label="$t('Province')"
                                :options="dbIller"
                                v-on:input="getIlceler"
                                :placeholder="$t('Province')"
                                :state="errors.length > 0 ? false:null"
                                :getOptionLabel="il => il.label"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('District')"
                        label-for="mc-ilce"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('District')"
                        >
                            <v-select
                                id="mc-ilce"
                                v-model="ilce"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :label="$t('District')"
                                :options="dbIlceler"
                                :placeholder="$t('District')"
                                :getOptionLabel="ilce => ilce.label"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('IsBuyuk')"
                        label-for="mc-isBuyuk"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('IsBuyuk')"
                        >
                            <div class="">
                                <b-form-checkbox
                                    v-model="isBuyuk"
                                    :value="true"
                                    id="mc-isBuyuk"
                                >
                                    {{ $t('IsBuyuk') }}
                                </b-form-checkbox>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!--                Test-->
<!--                <b-col-->
<!--                    md="6"-->
<!--                >-->
<!--                    <v-select-->
<!--                        id="mc-illllll"-->
<!--                        v-model="il"-->
<!--                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                        :label="$t('Province')"-->
<!--                        :options="dbIller"-->
<!--                        v-on:input="getIlceler"-->
<!--                        :placeholder="$t('Province')"-->
<!--                        :getOptionLabel="il => il.label"-->
<!--                    />-->
<!--                </b-col>-->
            </b-row>
            <div class="divider mt-0 mb-2">
                <div class="divider-text p-0">

                </div>
            </div>
            <b-row class="justify-content-center">
                <b-button type="submit"
                          variant="primary"
                          v-on:click="AddNew"
                >
                    {{ $t('Add User') }}
                </b-button>
            </b-row>
        </validation-observer>
    </b-card>
</template>

<script>
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate'
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import {BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox,} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vSelect from 'vue-select'

export default {
    name: "add-municipality",
    components: {
        BCard, BRow,
        BCol, BFormGroup,
        BButton,
        BFormCheckbox,
        BFormInput, vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            il: '',
            ilce: '',
            isBuyuk: false,
        }
    },
    computed: {
        dbIller() {
            return this.$store.state.municipalities.iller;
        },
        dbIlceler() {
            return this.$store.state.municipalities.ilceler;
        },
    },
    methods: {
        getIlceler(newIL) {
            this.$store.dispatch('municipalities/getIlceler', newIL.id)
                .then(res => {
                    this.ilce = "";
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
        AddNew() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let myPayload = {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        email: this.email,
                        phone: this.phone,
                        password: this.password,
                        ilce_id: this.ilce.id,
                        il_id: this.il.id,
                        isbuyuk: this.isBuyuk,
                        role:"belediye",

                    }
                    this.$store.dispatch("municipalities/addNewMuncipilityUser", myPayload)
                        .then(res => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Successfully'),
                                        icon: 'InfoIcon',
                                        text: res.data.message,
                                        variant: 'success',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        })
                        .catch(err => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'InfoIcon',
                                        text: err.response.data.message,
                                        variant: 'danger',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        })
                } else {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('Please Check Entered Data'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                }
            })
        },
    },
    created() {
        localize(this.$i18n.locale)
        if (this.dbIller.length < 1) {
            this.$store.dispatch('municipalities/getIller')
                .then(res => {
                    //console.log(this.$store.state.municipalities.iller);
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        }

    }
}
</script>

<style scoped>

</style>
